import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
  // InfoWindow,
  Circle,
} from "@react-google-maps/api";
import { TbCurrentLocation } from "react-icons/tb";
import { useCallback, useRef, useState } from "react";
// icons
import MarkerIcon from "../../assets/icons/location-position.png";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const Index = ({
  center,
  routeCoordinates = [],
  polylineOptions,
  markers = [],
  zoom,
  width = "100%",
  height = "100%",
  radius = 0,
  handleMarkerDrag = null,
  handleGetCurrentLocation = null,
  draggable = false,
}) => {
  const containerStyle = {
    width: width,
    height: height,
  };

  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  // const [selectedMarker, setSelectedMarker] = useState(null);
  // const [hoveredRoute, setHoveredRoute] = useState(null);
  // const [locationName, setLocationName] = useState("");

  // const handleMarkerMouseOver = useCallback((marker) => {
  //   const geocoder = new window.google.maps.Geocoder();

  //   geocoder.geocode({ location: marker.position }, (results, status) => {
  //     if (status === "OK" && results[0]) {
  //       setLocationName(results[0].formatted_address); // Get the address
  //       setSelectedMarker(marker); // Set the marker to show InfoWindow
  //     } else {
  //       console.error("Geocoder failed due to: " + status);
  //     }
  //   });
  // }, []);

  // Function to reverse geocode when hovering over polyline
  // const handlePolylineMouseOver = useCallback((event) => {
  //   const geocoder = new window.google.maps.Geocoder();

  //   geocoder.geocode({ location: event.latLng }, (results, status) => {
  //     if (status === "OK" && results[0]) {
  //       setLocationName(results[0].formatted_address); // Get the address
  //       setHoveredRoute({ position: event.latLng }); // Set hovered route position
  //     } else {
  //       console.error("Geocoder failed due to: " + status);
  //     }
  //   });
  // }, []);

  const mapOptions = {
    streetViewControl: false, // Disables the Street View control
    // fullscreenControl: true,  // Other controls as needed
    // zoomControl: true,
    mapTypeControl: false,
  };

  const [isBtnView, setIsBtnView] = useState(false);

  return (
    <LoadScript googleMapsApiKey={API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={() => {
          onLoad;
          setIsBtnView(true);
        }}
        onUnmount={onUnmount}
        options={mapOptions}
      >
        {/* {radius !== false && ( */}
        <Circle
          center={center}
          radius={radius}
          options={{
            strokeColor: "#4B0082",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#E6E6FA",
            fillOpacity: 0.35,
          }}
        />
        {/* )} */}
        {routeCoordinates.length && (
          <Polyline
            path={routeCoordinates}
            options={polylineOptions}
            // onMouseOver={handlePolylineMouseOver}
            // onMouseOut={() => setHoveredRoute(null)}
          />
        )}

        {/* InfoWindow for Polyline */}
        {/* {hoveredRoute && (
            <InfoWindow position={hoveredRoute.position}>
                <div>
                <h4>{locationName}</h4>
                </div>
            </InfoWindow>
            )} 
         */}

        {markers.map((marker) => (
          <Marker
            key={marker.id}
            label={marker.label}
            position={marker.position}
            icon={{
              url: MarkerIcon,
              width: "24px",
              height: "24px",
            }}
            draggable={draggable}
            onDragEnd={(event) => {
              handleMarkerDrag !== null
                ? handleMarkerDrag(event)
                : console.log();
            }}
            // onMouseOver={() => handleMarkerMouseOver(marker)}
            // onMouseOut={() => setSelectedMarker(null)}
          />
        ))}
        {/* InfoWindow for Marker */}
        {/* {selectedMarker && (
          <InfoWindow
            position={selectedMarker.position}
            onCloseClick={() => setSelectedMarker(null)}
          >
            <div>
              <h4>{locationName}</h4>
            </div>
          </InfoWindow>
        )} */}
      </GoogleMap>
      {isBtnView && (
        <span
          onClick={handleGetCurrentLocation}
          style={{
            position: "absolute",
            bottom: "165px",
            right: "10px",
            padding: "3px",
            cursor: "pointer",
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <TbCurrentLocation style={{ fontSize: "20px" }} />
        </span>
      )}
    </LoadScript>
  );
};

export default Index;
