import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;
const baseUrl = process.env.REACT_APP_BASE_URI;

let initialState = {
  shiftLoading: false,
  shiftSuccess: false,
  shiftError: false,
  shiftMessage: null,

  shiftGetLoading: false,
  shiftGetSuccess: false,
  shiftGetError: false,
  shiftGetMessage: null,
  shiftGetData: null,
};

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};

export const fetchData = createAsyncThunk("fetchData", async (userId) => {
  return await axios
    .get(`${baseUrl}admin/getMyShiftList?userId=${userId}`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
          data: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
        };
        return data;
      } else {
        return error;
      }
    });
});

export const saveShift = createAsyncThunk("saveShift", async (data) => {
  const result = {
    data: {
      message: "Sorry, you can only view the data, not create it.",
      data: [],
    },
  };
  return parseInt(accountType) === 2
    ? result
    : await axios
        .post(`${baseUrl}admin/createMyShift`, data, config)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
            let data = {
              response: {
                data: [],
                message: "No Internet Connection Found",
                success: false,
              },
            };
            return data;
          } else {
            return error;
          }
        });
});

const shiftSlice = createSlice({
  name: "shift",
  initialState,

  reducers: {
    initState: (state) => {
      state.shiftLoading = false;
      state.shiftSuccess = false;
      state.shiftError = false;
      state.shiftMessage = null;

      state.shiftGetLoading = false;
      state.shiftGetSuccess = false;
      state.shiftGetError = false;
      state.shiftGetMessage = null;
    },

    initShiftState: (state) => {
      state.shiftLoading = false;
      state.shiftSuccess = false;
      state.shiftError = false;
      state.shiftMessage = null;

      state.shiftGetLoading = false;
      state.shiftGetSuccess = false;
      state.shiftGetError = false;
      state.shiftGetMessage = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state, action) => {
      state.shiftGetLoading = true;
      state.shiftGetSuccess = false;
      state.shiftGetError = false;
      state.shiftGetMessage = null;
      state.shiftGetData = null;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.shiftGetLoading = false;
      state.shiftGetSuccess = true;
      state.shiftGetError = false;
      state.shiftGetMessage = action.payload.data.message;
      state.shiftGetData = action.payload.data.data;
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.shiftGetLoading = false;
      state.shiftGetSuccess = false;
      state.shiftGetError = true;
      state.shiftGetMessage = action.payload.data.message;
      state.shiftGetData = null;
    });
    // To create branch
    builder.addCase(saveShift.pending, (state, action) => {
      state.shiftLoading = true;
      state.shiftSuccess = false;
      state.shiftError = false;
      state.shiftMessage = null;
    });
    builder.addCase(saveShift.fulfilled, (state, action) => {
      state.shiftLoading = false;
      state.shiftSuccess = accountType == 2 ? false : true;
      state.shiftError = accountType == 2 ? true : false;
      state.shiftMessage = action.payload.data.message;
    });
    builder.addCase(saveShift.rejected, (state, action) => {
      state.shiftLoading = false;
      state.shiftSuccess = false;
      state.shiftError = true;
      state.shiftMessage = action.payload.data.message;
    });
  },
});
export const { initState, initShiftState } = shiftSlice.actions;
export default shiftSlice.reducer;
