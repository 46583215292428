import "./Shifts.css";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Form, Row, Col } from "react-bootstrap";
import ButtonCom from "../../../components/button/button";
import DatePicker from "react-datepicker";

import { CiEdit } from "react-icons/ci";
import { MdOutlineDeleteSweep } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";

import { useDispatch, useSelector } from "react-redux";
import {
  saveShift,
  fetchData,
  initState,
} from "../../../redux/slices/shiftsSlice";
import {
  commonSaveData,
  initCommonState,
} from "../../../redux/slices/commonSlice";
import { ISOTimeFormat } from "../../../services/commonServices";

const Shifts = () => {
  const [time, setTime] = useState("12:34pm");

  const hours = Array.from({ length: 12 }, (_, i) => i + 1);
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5);

  const user = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : [];
  const dispatch = useDispatch();
  const state = useSelector((state) => state.shift);
  const commonState = useSelector((state) => state.common);

  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(false);

  // Circular timepicker function and variables ===========================
  const [isFromTime, setIsFromTime] = useState(false);
  const openFromTimePicker = () => {
    setIsFromTime(!isFromTime);
  };

  const [isToTime, setIsToTime] = useState(false);
  const openToTimePicker = () => {
    setIsToTime(!isToTime);
  };

  const [selectedHour, setSelectedHour] = useState(12);
  const [selectedMinute, setSelectedMinute] = useState(0);

  const [selectedHourTo, setSelectedHourTo] = useState(12);
  const [selectedMinuteTo, setSelectedMinuteTo] = useState(0);

  const [ampm, setAmPm] = useState("AM");
  const [ampmTo, setAmPmTo] = useState("AM");

  const radius = 100;
  selectedHour;
  const center = 120;
  // Circular timepicker function and variables ===========================

  const [shiftList, setShiftList] = useState([]);
  const [shiftData, setShiftData] = useState({
    userId: user.user_id,
    shiftId: null,
    shiftName: "",
    shiftStartTime: "12:0 AM",
    shiftStartTime1: null,
    shiftEndTime: "12:0 AM",
    shiftEndTime1: null,
    punchInType: "",
    // Below 2field based on this field condition punchInType
    Punch_In_Duration_hour: "",
    Punch_In_Duration_minutes: "",
    punchOutType: "",
    // Below 2field based on this field condition punchOutType
    Punch_Out_Duration_hour: "",
    Punch_Out_Duration_minutes: "",
  });

  const setHours = (hours, name) => {
    if (name == "shiftStartTime") {
      setSelectedHour(hours);
      setShiftData({
        ...shiftData,
        [name]: hours + ":" + selectedMinute + " " + ampm,
      });
    } else {
      setSelectedHourTo(hours);
      setShiftData({
        ...shiftData,
        [name]: hours + ":" + selectedMinuteTo + " " + ampmTo,
      });
    }
  };
  const setMinutes = (minutes, name) => {
    if (name == "shiftStartTime") {
      setSelectedMinute(minutes);
      setShiftData({
        ...shiftData,
        [name]: selectedHour + ":" + minutes + " " + ampm,
      });
    } else {
      setSelectedMinuteTo(minutes);
      setShiftData({
        ...shiftData,
        [name]: selectedHourTo + ":" + minutes + " " + ampmTo,
      });
    }
  };
  const setTimeAmPm = (ampm, name) => {
    if (name == "shiftStartTime") {
      setAmPm(ampm);
      setShiftData({
        ...shiftData,
        [name]: selectedHour + ":" + selectedMinute + " " + ampm,
      });
    } else {
      setAmPmTo(ampm);
      setShiftData({
        ...shiftData,
        [name]: selectedHourTo + ":" + selectedMinuteTo + " " + ampm,
      });
    }
  };

  const [punchInTypeErr, setPunchInTypeErr] = useState(false);
  const [punchOutTypeErr, setPunchOutTypeErr] = useState(false);

  const getShiftList = useCallback(() => {
    dispatch(fetchData(shiftData.userId));
  }, []);

  useEffect(() => {
    getShiftList();
  }, []);

  useEffect(() => {
    if (state.shiftGetSuccess) {
      setShiftList(state.shiftGetData);
    }
  }, [state.shiftGetSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShiftData({
      ...shiftData,
      shiftName: "",
      shiftStartTime: "",
      shiftEndTime: "",
      punchInType: "",
      // Below 2field based on this field condition punchInType
      Punch_In_Duration_hour: "",
      Punch_In_Duration_minutes: "",
      punchOutType: "",
      // Below 2field based on this field condition punchOutType
      Punch_Out_Duration_hour: "",
      Punch_Out_Duration_minutes: "",
    });
    setValidated(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "punchInType") {
      setPunchInTypeErr(false);
    }

    if (name == "punchOutType") {
      setPunchOutTypeErr(false);
    }

    setShiftData({
      ...shiftData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (shiftData.punchInType == "") {
      setPunchInTypeErr(true);
    }
    if (shiftData.punchOutType == "") {
      setPunchOutTypeErr(true);
    }
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(saveShift(shiftData));
    }
  };

  useEffect(() => {
    if (state.shiftSuccess) {
      handleClose();
      toast.success(state.shiftMessage);
      dispatch(initState());
      setShiftData({
        ...shiftData,
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: "",
        punchInType: "",
        Punch_In_Duration_hour: "",
        Punch_In_Duration_minutes: "",
        punchOutType: "",
        Punch_Out_Duration_hour: "",
        Punch_Out_Duration_minutes: "",
      });
      getShiftList();
    }
    if (state.shiftError) {
      handleClose();
      dispatch(initState());
      setShiftData({
        ...shiftData,
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: "",
        punchInType: "",
        // Below 2field based on this field condition punchInType
        Punch_In_Duration_hour: "",
        Punch_In_Duration_minutes: "",
        punchOutType: "",
        // Below 2field based on this field condition punchOutType
        Punch_Out_Duration_hour: "",
        Punch_Out_Duration_minutes: "",
      });
      toast.error(state.shiftMessage);
    }
  }, [state.shiftSuccess, state.shiftError]);

  // Edit functions

  const [show, setShow] = useState(false);

  const handleModalClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);

  const handleEdit = (row) => {
    setIsEdit(true);

    setShiftData({
      ...shiftData,
      shiftId: row.shiftId,
      shiftName: row.shift_name,
      // shiftStartTime: new Date(row.shift_start_time),
      // shiftEndTime: new Date(row.shift_end_time),

      shiftStartTime: row.shift_start_time,
      shiftEndTime: row.shift_end_time,

      punchInType: row.can_punchintype,
      // Below 2field based on this field condition punchInType
      Punch_In_Duration_hour: row.allow_punchinhour,
      Punch_In_Duration_minutes: row.allow_punchinminutes,
      punchOutType: row.can_punchouttype,
      // Below 2field based on this field condition punchOutType
      Punch_Out_Duration_hour: row.allow_punchouthour,
      Punch_Out_Duration_minutes: row.allow_punchoutminutes,
    });
    handleClickOpen();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    let d = {
      path: "admin/updateShift",
      data: shiftData,
    };
    dispatch(commonSaveData(d));
  };

  // Delete functions
  const [delId, setDelId] = useState(null);
  const handleDelete = (row) => {
    setDelId(row.shiftId);
    handleShow();
  };
  const handleDeleteSubmit = () => {
    let d = {
      path: "admin/deleteShift",
      data: {
        userId: user?.user_id,
        shiftId: delId,
      },
    };
    dispatch(commonSaveData(d));
    handleModalClose();
  };

  useEffect(() => {
    if (commonState.commonSuccess) {
      toast.success(commonState.commonMessage);
      setOpen(false);
      getShiftList();
      setIsEdit(false);
      dispatch(initCommonState());

      setShiftData({
        ...shiftData,
        shiftId: "",
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: "",
        punchInType: "",
        Punch_In_Duration_hour: "",
        Punch_In_Duration_minutes: "",
        punchOutType: "",
        Punch_Out_Duration_hour: "",
        Punch_Out_Duration_minutes: "",
      });
    }
    if (commonState.commonError) {
      toast.error(commonState.commonMessage);
      setOpen(false);
      getShiftList();
      dispatch(initCommonState());
      setShiftData({
        ...shiftData,
        shiftId: "",
        shiftName: "",
        shiftStartTime: "",
        shiftEndTime: "",
        punchInType: "",
        Punch_In_Duration_hour: "",
        Punch_In_Duration_minutes: "",
        punchOutType: "",
        Punch_Out_Duration_hour: "",
        Punch_Out_Duration_minutes: "",
      });
    }
  }, [commonState.commonSuccess, commonState.commonError]);

  const columns = [
    {
      headerName: "Shift Name",
      field: "shift_name",
    },
    {
      headerName: "Shift Start Timings",
      field: "shift_start_time",
      // cellRenderer: (rowData) => {
      //   const date = new Date(rowData.data.shift_start_time);
      //   const time = date.toLocaleTimeString("en-US", {
      //     hour: "numeric",
      //     minute: "numeric",
      //     hour12: true,
      //   });
      //   return time;
      // },
    },
    {
      headerName: "Punch In",
      cellRenderer: (rowData) => {
        return rowData.data.can_punchintype == "Allow Limit"
          ? rowData.data.allow_punchinhour +
              ":" +
              rowData.data.allow_punchinminutes
          : rowData.data.can_punchintype;
      },
    },
    {
      headerName: "Shift End Timings",
      field: "shift_end_time",
      // cellRenderer: (rowData) => {
      //   const date = new Date(rowData.data.shift_end_time);
      //   const time = date.toLocaleTimeString("en-US", {
      //     hour: "numeric",
      //     minute: "numeric",
      //     hour12: true,
      //   });
      //   return time;
      // },
    },
    {
      headerName: "Punch Out",
      cellRenderer: (rowData) => {
        return rowData.data.can_punchouttype == "Allow Limit"
          ? rowData.data.allow_punchouthour +
              ":" +
              rowData.data.allow_punchoutminutes
          : rowData.data.can_punchouttype;
      },
    },
    {
      headerName: "Status",
      cellRenderer: (rowData) => {
        return rowData.data.status == 1 ? "Active" : "InActive";
      },
    },
    {
      headerName: "Actions",
      cellRenderer: (row) => {
        return (
          <div>
            <CiEdit
              style={{
                cursor: "pointer",
                fontSize: 30,
                color: "blue",
                marginRight: "10px",
              }}
              onClick={() => {
                handleEdit(row.data);
              }}
            />
            <MdOutlineDeleteSweep
              style={{ cursor: "pointer", fontSize: 30, color: "red" }}
              onClick={() => handleDelete(row.data)}
            />
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "200px", // Set a width for the action buttons
    },
  ];

  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 20, 50, 100];

  return (
    <div className="SettingBody">
      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure! to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "gray",
              color: "#fff",
              marginRight: "5px",
            }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleDeleteSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        className="toast-custom"
      />
      <div className="SettingBody-head">
        <Link to={"/setting/"}>
          <ArrowBackIcon sx={{ scale: 1 }} />
        </Link>
        <p>Company Shifts</p>
      </div>

      <div className="SettingBody-card mt-4" style={{ borderRadius: "7px" }}>
        <div className="Branches-body" style={{ borderRadius: "7px" }}>
          <div className="Branches-btn">
            <ButtonCom
              type={"button"}
              class_name={"p_btn"}
              text={"Add Shift"}
              ext
              url_or_onclick={handleClickOpen}
              icon={<AddIcon />}
              width={""}
            />
          </div>
          <div
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{ height: "500px", width: "100%" }}
          >
            <AgGridReact
              rowData={shiftList.length > 0 ? shiftList : []}
              columnDefs={columns}
              omLayout="autoHeight"
              pagination={true}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
            />
          </div>

          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle style={{ fontSize: "18px", fontWeight: "bold" }}>
              {isEdit ? "Update Shift" : "Add New Shift"}
            </DialogTitle>
            <div style={{ overflow: "auto" }}>
              <Form
                noValidate
                validated={validated}
                onSubmit={isEdit ? handleEditSubmit : handleSubmit}
              >
                <DialogContent>
                  <Row>
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Shift Name <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="shiftName"
                          value={shiftData.shiftName}
                          placeholder="Enter Shift Name"
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid shift name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* --------------------------------------------------- */}
                    <Col sm="12">
                      <Form.Group
                        className="mb-2"
                        style={{ position: "relative" }}
                      >
                        <Form.Label>
                          Shift Start Time{" "}
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          name={"shiftStartTime"}
                          onClick={openFromTimePicker}
                          value={shiftData.shiftStartTime}
                          placeholder="Select From Time"
                        />

                        {isFromTime && (
                          <div className="circular-timepicker">
                            <svg width="240" height="240">
                              <circle
                                cx={center}
                                cy={center}
                                r={radius}
                                fill="#E0F7FA"
                              />
                              {/* Render hours */}
                              {hours.map((hour, index) => {
                                const angle = index * 30 * (Math.PI / 180); // convert to radians
                                const x =
                                  center + radius * 0.8 * Math.cos(angle);
                                const y =
                                  center - radius * 0.8 * Math.sin(angle);
                                return (
                                  <text
                                    key={hour}
                                    x={x}
                                    y={y}
                                    textAnchor="middle"
                                    fontSize="16"
                                    fill={
                                      selectedHour === hour ? "blue" : "black"
                                    }
                                    onClick={() =>
                                      setHours(hour, "shiftStartTime")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {hour}
                                  </text>
                                );
                              })}
                              {/* Render minutes */}
                              {minutes.map((minute, index) => {
                                const angle = index * 30 * (Math.PI / 180);
                                const x =
                                  center + radius * 0.5 * Math.cos(angle);
                                const y =
                                  center - radius * 0.5 * Math.sin(angle);
                                return (
                                  <text
                                    key={minute}
                                    x={x}
                                    y={y}
                                    textAnchor="middle"
                                    fontSize="12"
                                    fill={
                                      selectedMinute === minute
                                        ? "blue"
                                        : "black"
                                    }
                                    onClick={() =>
                                      setMinutes(minute, "shiftStartTime")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {minute.toString().padStart(2, "0")}
                                  </text>
                                );
                              })}
                            </svg>
                            <div className="am-pm-container">
                              <div
                                className="am"
                                onClick={() => {
                                  setTimeAmPm("AM", "shiftStartTime");
                                }}
                              >
                                AM
                              </div>
                              <div
                                className="pm"
                                onClick={() =>
                                  setTimeAmPm("PM", "shiftStartTime")
                                }
                              >
                                PM
                              </div>
                            </div>
                            <div
                              className="time-taking-done"
                              onClick={() => {
                                setIsFromTime(false);
                              }}
                            >
                              Done
                            </div>
                            {/* <div>
                              Selected Time: {selectedHour}:
                              {selectedMinute.toString().padStart(2, "0")}{" "}
                              {ampm}
                            </div> */}
                          </div>
                        )}

                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid start timing.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* ----------------------------------------------------- */}
                  </Row>

                  <Row>
                    <Col sm="12">
                      <Form.Group>
                        <Form.Label>
                          Can punch in
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="AddShift-radio"
                            htmlFor="punchInType1"
                          >
                            <Form.Check
                              name="punchInType"
                              type="radio"
                              required
                              label="Any time"
                              value={"Allow Anytime"}
                              feedbackType="invalid"
                              id="punchInType1"
                              onChange={handleChange}
                              checked={
                                shiftData.punchInType == "Allow Anytime"
                                  ? true
                                  : false
                              }
                            ></Form.Check>
                          </div>

                          <div
                            className="AddShift-radio"
                            htmlFor="punchInType2"
                          >
                            <Form.Check
                              name="punchInType"
                              value={"Allow Limit"}
                              type="radio"
                              required
                              label="Add Limit"
                              feedbackType="invalid"
                              id="punchInType2"
                              onChange={handleChange}
                              checked={
                                shiftData.punchInType == "Allow Limit"
                                  ? true
                                  : false
                              }
                            ></Form.Check>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            Please select valid type.
                          </Form.Control.Feedback>
                        </div>
                        {punchInTypeErr && (
                          <label style={{ color: "red", fontSize: "14px" }}>
                            Please choose any one option.
                          </label>
                        )}

                        {shiftData.punchInType == "Allow Limit" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              gap: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <Form.Group style={{ width: "48%" }}>
                              <Form.Label>Hours</Form.Label>
                              <Form.Control
                                type="text"
                                name="Punch_In_Duration_hour"
                                value={shiftData.Punch_In_Duration_hour}
                                placeholder="Hours"
                                onChange={handleChange}
                                required={
                                  shiftData.punchInType == "Allow Limit"
                                    ? true
                                    : false
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide valid hours
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group style={{ width: "48%" }}>
                              <Form.Label>Minutes</Form.Label>
                              <Form.Control
                                type="text"
                                name="Punch_In_Duration_minutes"
                                value={shiftData.Punch_In_Duration_minutes}
                                placeholder="Minutes"
                                onChange={handleChange}
                                required={
                                  shiftData.punchInType == "Allow Limit"
                                    ? true
                                    : false
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide valid minutes
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col sm="12">
                      <Form.Group className="mb-2">
                        <Form.Label>
                          Shift End Time{" "}
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          readOnly
                          name={"shiftEndTime"}
                          onClick={openToTimePicker}
                          value={shiftData.shiftEndTime}
                          placeholder="Select To Time"
                        />

                        {isToTime && (
                          <div className="circular-timepicker-1">
                            <svg width="240" height="240">
                              <circle
                                cx={center}
                                cy={center}
                                r={radius}
                                fill="#E0F7FA"
                              />
                              {/* Render hours */}
                              {hours.map((hour, index) => {
                                const angle = index * 30 * (Math.PI / 180); // convert to radians
                                const x =
                                  center + radius * 0.8 * Math.cos(angle);
                                const y =
                                  center - radius * 0.8 * Math.sin(angle);
                                return (
                                  <text
                                    key={hour}
                                    x={x}
                                    y={y}
                                    textAnchor="middle"
                                    fontSize="16"
                                    fill={
                                      selectedHourTo === hour
                                        ? "blue"
                                        : "black"
                                    }
                                    onClick={() =>
                                      setHours(hour, "shiftEndTime")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {hour}
                                  </text>
                                );
                              })}
                              {/* Render minutes */}
                              {minutes.map((minute, index) => {
                                const angle = index * 30 * (Math.PI / 180);
                                const x =
                                  center + radius * 0.5 * Math.cos(angle);
                                const y =
                                  center - radius * 0.5 * Math.sin(angle);
                                return (
                                  <text
                                    key={minute}
                                    x={x}
                                    y={y}
                                    textAnchor="middle"
                                    fontSize="12"
                                    fill={
                                      selectedMinuteTo === minute
                                        ? "blue"
                                        : "black"
                                    }
                                    onClick={() =>
                                      setMinutes(minute, "shiftEndTime")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {minute.toString().padStart(2, "0")}
                                  </text>
                                );
                              })}
                            </svg>
                            <div className="am-pm-container">
                              <div
                                className="am"
                                onClick={() => {
                                  setTimeAmPm("AM", "shiftEndTime");
                                }}
                              >
                                AM
                              </div>
                              <div
                                className="pm"
                                onClick={() =>
                                  setTimeAmPm("PM", "shiftEndTime")
                                }
                              >
                                PM
                              </div>
                            </div>
                            <div
                              className="time-taking-done"
                              onClick={() => {
                                setIsToTime(false);
                              }}
                            >
                              Done
                            </div>
                            {/* <div>
                              Selected Time: {selectedHour}:
                              {selectedMinute.toString().padStart(2, "0")}{" "}
                              {ampm}
                            </div> */}
                          </div>
                        )}

                        <Form.Control.Feedback type={"invalid"}>
                          Please provide valid end timing.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    {/* ----------------------------------------------------- */}
                    {/* -------------------------------------- */}
                    <Col sm="12">
                      <Form.Group>
                        <Form.Label>
                          Can punch out
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="AddShift-radio"
                            htmlFor="punchOutType1"
                          >
                            <Form.Check
                              name="punchOutType"
                              type="radio"
                              required
                              label="Any time"
                              value={"Allow Anytime"}
                              feedbackType="invalid"
                              id="punchOutType1"
                              onChange={handleChange}
                              checked={
                                shiftData.punchOutType == "Allow Anytime"
                                  ? true
                                  : false
                              }
                            ></Form.Check>
                          </div>

                          <div
                            className="AddShift-radio"
                            htmlFor="punchOutType2"
                          >
                            <Form.Check
                              name="punchOutType"
                              value={"Allow Limit"}
                              type="radio"
                              required
                              label="Add Limit"
                              feedbackType="invalid"
                              id="punchOutType2"
                              onChange={handleChange}
                              checked={
                                shiftData.punchOutType == "Allow Limit"
                                  ? true
                                  : false
                              }
                            ></Form.Check>
                          </div>

                          <Form.Control.Feedback type="invalid">
                            Please select valid type.
                          </Form.Control.Feedback>
                        </div>
                        {punchOutTypeErr && (
                          <label style={{ color: "red", fontSize: "14px" }}>
                            Please choose any one option.
                          </label>
                        )}

                        {shiftData.punchOutType == "Allow Limit" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              gap: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <Form.Group style={{ width: "48%" }}>
                              <Form.Label>Hours</Form.Label>
                              <Form.Control
                                type="text"
                                name="Punch_Out_Duration_hour"
                                value={shiftData.Punch_Out_Duration_hour}
                                placeholder="Hours"
                                onChange={handleChange}
                                required={
                                  shiftData.punchOutType == "Allow Limit"
                                    ? true
                                    : false
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide valid hours
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group style={{ width: "48%" }}>
                              <Form.Label>Minutes</Form.Label>
                              <Form.Control
                                type="text"
                                name="Punch_Out_Duration_minutes"
                                value={shiftData.Punch_Out_Duration_minutes}
                                placeholder="Minutes"
                                onChange={handleChange}
                                required={
                                  shiftData.punchOutType == "Allow Limit"
                                    ? true
                                    : false
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide valid minutes
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="gray"
                    onClick={handleClose}
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                      backgroundColor: "gray",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    type="submit"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      lineHeight: "30px",
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

// const AddShiftForm = () => {
//   return (
//     <div className="DepartmentsForm">
//       <h3>Add New Shift </h3>
//       <form>
//         <div className="BasicDetails-input">
//           <label>
//             Shift Name
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <input name="" type="text" placeholder={"Shift Name"} />
//         </div>

//         <div className="BasicDetails-input">
//           <label style={{ width: "100%" }}>
//             Shift Start Name
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <input
//             style={{ width: 300 }}
//             name=""
//             type="text"
//             placeholder={"Shift Name"}
//           />
//         </div>

//         <div className="BasicDetails-input">
//           <label style={{ width: "100%" }}>
//             Can punch in
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <div className="AddShift-radio">
//             <input name="" type="radio" placeholder={"Shift Name"} />
//             <label>Any time</label>
//           </div>

//           <div className="AddShift-radio">
//             <input name="" type="radio" placeholder={"Shift Name"} />
//             <label>Add Limit</label>
//           </div>
//         </div>

//         <div className="BasicDetails-input">
//           <label style={{ width: "100%" }}>
//             Shift End Time
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <input
//             style={{ width: 300 }}
//             name=""
//             type="text"
//             placeholder={"Shift Name"}
//           />
//         </div>

//         <div className="BasicDetails-input">
//           <label style={{ width: "100%" }}>
//             Can punch Out
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <div className="AddShift-radio">
//             <input name="" type="radio" placeholder={"Shift Name"} />
//             <label>Any time</label>
//           </div>

//           <div className="AddShift-radio">
//             <input name="" type="radio" placeholder={"Shift Name"} />
//             <label>Add Limit</label>
//           </div>
//         </div>

//         <div className="BasicDetails-input">
//           <label style={{ width: "100%" }}>
//             All punch Out
//             <span style={{ color: "red" }}> *</span>
//           </label>
//           <div className="AddShift-radio AddShift-input">
//             <input name="" type="text" placeholder="" />
//             <label>Any time</label>
//           </div>

//           <div className="AddShift-radio AddShift-input ">
//             <input name="" type="text" placeholder="" />
//             <label>Add Limit</label>
//           </div>
//         </div>
//       </form>
//       <div className="pop-Form-btn">
//         <button>Cancel</button>
//         <button>Save</button>
//       </div>
//     </div>
//   );
// };

export default Shifts;
