import {
  Cards,
  WishesCards,
  WishesCardsUserInfo,
} from "../../components/cards/Cards";
import "./Dashboard.css";
import Avatar from "@mui/material/Avatar";
import { BarChart } from "@mui/x-charts/BarChart";
import { useCallback, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { commonGetData, commonGetDataOne, initCommonState } from "../../redux/slices/commonSlice";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [user] = useState(
    localStorage.getItem("user_data")
      ? JSON.parse(localStorage.getItem("user_data"))
      : []
  );

  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.common);
  // stat variable
  // const [userRecord, setUserRecord] = useState({});
  const [isAlert, setAlert] = useState(false);
  const [isBranchAlert, setBranchAlert] = useState(false);
  const [isDepartmentAlert, setDepartmentAlert] = useState(false);
  const [isEmployerIdAlert, setEmployerIdAlert] = useState(false);
  const [isShiftAlert, setShiftAlert] = useState(false);

  const getUserRecord = useCallback(() => {
    dispatch(commonGetData(`/admin/checkUserRecords?userId=${user.user_id}`));
  });

  useEffect(() => {
    getUserRecord();
  }, []);

  useEffect(() => {
    if (commonState.commonGetSuccess) {
      for (let key in commonState.commonGetData) {
        if (commonState.commonGetData.hasOwnProperty(key)) {
          if (
            key == "isBranchRecord" &&
            commonState.commonGetData[key] === false
          ) {
            setBranchAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isDepartmentRecord" &&
            commonState.commonGetData[key] === false
          ) {
            setDepartmentAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isEmployerId" &&
            commonState.commonGetData[key] === false
          ) {
            setEmployerIdAlert(true);
            setAlert(true);
            break;
          } else if (
            key == "isShift" &&
            commonState.commonGetData[key] === false
          ) {
            setShiftAlert(true);
            setAlert(true);
            break;
          }
        }
      }
      // setUserRecord(commonState.commonGetData);
      dispatch(initCommonState());
    }
    if (commonState.commonGetError) {
      dispatch(initCommonState());
    }
  }, [commonState.commonGetSuccess, commonState.commonGetError]);

  const path = `admin/getEmployeeStaffList?userId=${user.user_id}`;

  const [usersList, setUsersList] = useState([]);

  const getEmployeeList = useCallback(() => {
    dispatch(commonGetDataOne(path));
  }, []);

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    if (commonState.commonGetOneSuccess) {
      if (commonState.commonGetOneData == undefined || commonState.commonGetOneData == "") {
        setUsersList([]);
        dispatch(initCommonState())
      } else {
        setUsersList(commonState.commonGetOneData);
        dispatch(initCommonState())
      }
    }
  }, [commonState.commonGetOneSuccess]);

  return (
    <div className="Dashboard">
      <div className="Dashboard-head">
        <div className="Dashboard-info">
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/1.jpg"
            sx={{ width: 70, height: 70 }}
          />
          <h1>{user.company_name}</h1>
        </div>
        <div className="Dashboard-card">
          {isAlert && (
            <div className="setting-notification">
              <div className="record-div">
                <div>
                  Records in your settings are not configured. To start, click
                  the link to complete.
                </div>

                {isBranchAlert && (
                  <Link to="/company-branches">Add Branch</Link>
                )}
                {isDepartmentAlert && (
                  <Link to="/company-departments">Add Department</Link>
                )}
                {isEmployerIdAlert && (
                  <Link to="/my-employee-id">Add Employer Id</Link>
                )}
                {isShiftAlert && (
                  <Link to="/attendance-settings-shifts">Add Shift</Link>
                )}
              </div>
            </div>
          )}
          <div className="Dashboard-card-items mb-5">
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Total Employees"}
              count={usersList.length}
              supCount={usersList.length}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"Active Employees"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"New Joiners"}
              count={134}
              supCount={""}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"On Duty"}
              count={134}
              supCount={134}
            />
            <Cards
              avatarSrc={"/static/images/avatar/1.jpg"}
              title={"On Leave"}
              count={134}
              supCount={134}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="Dashboard-bar-chart">
        <BarChart
          series={[
            { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
            { data: [30, 44, 24, 34, 30, 44, 24, 34, 30, 44, 24, 34] },
          ]}
          height={290}
          xAxis={[
            {
              data: [
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
                "January",
                "February",
                "March",
              ],
              scaleType: "band",
            },
          ]}
          margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
        />
      </div>

      <div className="Dashboard-wishes-container">
        {/* Birthday Wishes */}
        <WishesCards title={"Birthday Wishes"}>
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"Name"}
          />
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"name"}
          />
        </WishesCards>

        {/* Work Anniversary */}
        <WishesCards title={"Work Anniversary"}>
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"Name"}
          />
          <WishesCardsUserInfo
            avatarSrc={"/static/images/avatar/1.jpg"}
            userName={"name"}
          />
        </WishesCards>
      </div>
    </div>
  );
};

export default Dashboard;
