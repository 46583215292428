import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URI;
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 7fa5d115dbbf825a0a4abb2b2a7f37de`,
  },
};
const user = localStorage.getItem("user_data")
  ? JSON.parse(localStorage.getItem("user_data"))
  : null;
// const userId = user?.user_id;
const accountType = user?.account_type;

const initialState = {
  commonLoading: false,
  commonSuccess: false,
  commonError: false,
  commonMessage: null,
  commonData: null,

  commonGetLoading: false,
  commonGetSuccess: false,
  commonGetError: false,
  commonGetMessage: null,
  commonGetData: null,

  commonGetOneLoading: false,
  commonGetOneSuccess: false,
  commonGetOneError: false,
  commonGetOneMessage: null,
  commonGetOneData: null,

  commonGetTwoLoading: false,
  commonGetTwoSuccess: false,
  commonGetTwoError: false,
  commonGetTwoMessage: null,
  commonGetTwoData: null,

  commonGetThirdLoading: false,
  commonGetThirdSuccess: false,
  commonGetThirdError: false,
  commonGetThirdMessage: null,
  commonGetThirdData: null,
};

export const commonSaveData = createAsyncThunk(
  "commonSaveData",
  async (body) => {
    const { path, data } = body;
    const url = baseUrl + path;

    const result = {
      data: {
        status: 200,
        message: "Sorry, you can only view the data, not create it.",
        data: [],
      },
    };
    return parseInt(accountType) === 2
      ? result
      : await axios
          .post(url, data, config)
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if (!navigator.onLine) {
              let data = {
                response: {
                  data: [],
                  message: "No Internet Connection Found",
                  success: false,
                },
              };
              return data;
            } else {
              return error;
            }
          });
  }
);

export const commonGetData = createAsyncThunk("commonGetData", async (path) => {
  return await axios
    .get(`${baseUrl}${path}`, {}, config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!navigator.onLine) {
        let data = {
          response: {
            data: [],
            message: "No Internet Connection Found",
            success: false,
          },
          message: "No Internet Connection Found",
        };
        return data;
      } else {
        let data = {
          response : {
            data: {
              message: error.message
            }
          }
        }
        console.log(data)
        return error;
      }
    });
});

export const commonGetDataOne = createAsyncThunk(
  "commonGetDataOne",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            message: "No Internet Connection Found",
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

export const commonGetDataTwo = createAsyncThunk(
  "commonGetDataTwo",
  async (path) => {
    console.log(path);
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            message: "No Internet Connection Found",
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

export const commonGetDataThird = createAsyncThunk(
  "commonGetDataThird",
  async (path) => {
    return await axios
      .get(`${baseUrl}${path}`, {}, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (!navigator.onLine) {
          let data = {
            response: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
            data: {
              data: [],
              message: "No Internet Connection Found",
              success: false,
            },
          };
          return data;
        } else {
          return error;
        }
      });
  }
);

const CommonSlice = createSlice({
  name: "common",
  initialState,

  reducers: {
    initState: (state) => {
      state.commonLoading = false;
      state.commonSuccess = false;
      state.commonError = false;
      state.commonMessage = null;
      state.commonData = null;

      state.commonGetLoading = false;
      state.commonGetSuccess = false;
      state.commonGetError = false;
      state.commonGetMessage = null;
      state.commonGetData = null;

      state.commonGetOneLoading = false;
      state.commonGetOneSuccess = false;
      state.commonGetOneError = false;
      state.commonGetOneMessage = null;
      state.commonGetOneData = null;

      state.commonGetTwoLoading = false;
      state.commonGetTwoSuccess = false;
      state.commonGetTwoError = false;
      state.commonGetTwoMessage = null;
      state.commonGetTwoData = null;

      state.commonGetThirdLoading = false;
      state.commonGetThirdSuccess = false;
      state.commonGetThirdError = false;
      state.commonGetThirdMessage = null;
      state.commonGetThirdData = null;
    },
    initCommonState: (state) => {
      state.commonLoading = false;
      state.commonSuccess = false;
      state.commonError = false;
      state.commonMessage = null;
      state.commonData = null;

      state.commonGetLoading = false;
      state.commonGetSuccess = false;
      state.commonGetError = false;
      state.commonGetMessage = null;
      state.commonGetData = null;

      state.commonGetOneLoading = false;
      state.commonGetOneSuccess = false;
      state.commonGetOneError = false;
      state.commonGetOneMessage = null;
      state.commonGetOneData = null;

      state.commonGetTwoLoading = false;
      state.commonGetTwoSuccess = false;
      state.commonGetTwoError = false;
      state.commonGetTwoMessage = null;
      state.commonGetTwoData = null;

      state.commonGetThirdLoading = false;
      state.commonGetThirdSuccess = false;
      state.commonGetThirdError = false;
      state.commonGetThirdMessage = null;
      state.commonGetThirdData = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(commonGetData.pending, (state, action) => {
      state.commonGetLoading = true;
      state.commonGetSuccess = false;
      state.commonGetError = false;
      state.commonGetMessage = null;
      state.commonGetData = null;
    });

    builder.addCase(commonGetData.fulfilled, (state, action) => {
      console.log(action);
      state.commonGetLoading = false;
      state.commonGetSuccess = true;
      state.commonGetError = false;
      state.commonGetMessage =
        action.payload.status == 200
          ? action.payload.data.message
          : action.payload.response.data.message;
      state.commonGetData =
        action.payload.status == 200
          ? action.payload.data.data
          : action.payload.response.data.data;
    });

    builder.addCase(commonGetData.rejected, (state, action) => {
      state.commonGetLoading = false;
      state.commonGetSuccess = true;
      state.commonGetError = false;
      state.commonGetMessage = null;
      state.commonGetData = null;
    });
    // -----------------------------------------
    builder.addCase(commonGetDataOne.pending, (state, action) => {
      state.commonGetOneLoading = true;
      state.commonGetOneSuccess = false;
      state.commonGetOneError = false;
      state.commonGetOneMessage = null;
      state.commonGetOneData = null;
    });
    builder.addCase(commonGetDataOne.fulfilled, (state, action) => {
      state.commonGetOneLoading = false;
      state.commonGetOneSuccess = true;
      state.commonGetOneError = false;
      state.commonGetOneMessage = action.payload.data.message;
      state.commonGetOneData = action.payload.data.data;
    });
    builder.addCase(commonGetDataOne.rejected, (state, action) => {
      state.commonGetOneLoading = false;
      state.commonGetOneSuccess = true;
      state.commonGetOneError = false;
      state.commonGetOneMessage = null;
      state.commonGetOneData = null;
    });
    // -----------------------------------------
    builder.addCase(commonGetDataTwo.pending, (state, action) => {
      state.commonGetTwoLoading = true;
      state.commonGetTwoSuccess = false;
      state.commonGetTwoError = false;
      state.commonGetTwoMessage = null;
      state.commonGetTwoData = null;
    });
    builder.addCase(commonGetDataTwo.fulfilled, (state, action) => {
      state.commonGetTwoLoading = false;
      state.commonGetTwoSuccess = true;
      state.commonGetTwoError = false;
      state.commonGetTwoMessage = action.payload.data.message;
      state.commonGetTwoData = action.payload.data.data;
    });
    builder.addCase(commonGetDataTwo.rejected, (state, action) => {
      state.commonGetTwoLoading = false;
      state.commonGetTwoSuccess = true;
      state.commonGetTwoError = false;
      state.commonGetTwoMessage = null;
      state.commonGetTwoData = null;
    });

    // -----------------------------------------
    builder.addCase(commonGetDataThird.pending, (state, action) => {
      state.commonGetThirdLoading = true;
      state.commonGetThirdSuccess = false;
      state.commonGetThirdError = false;
      state.commonGetThirdMessage = null;
      state.commonGetThirdData = null;
    });
    builder.addCase(commonGetDataThird.fulfilled, (state, action) => {
      console.log(action);
      state.commonGetThirdLoading = false;
      state.commonGetThirdSuccess = true;
      state.commonGetThirdError = false;
      state.commonGetThirdMessage = action.payload.data.message;
      state.commonGetThirdData = action.payload.data.data;
    });
    builder.addCase(commonGetDataThird.rejected, (state, action) => {
      state.commonGetThirdLoading = false;
      state.commonGetThirdSuccess = true;
      state.commonGetThirdError = false;
      state.commonGetThirdMessage = null;
      state.commonGetThirdData = null;
    });

    builder.addCase(commonSaveData.pending, (state) => {
      state.commonLoading = true;
      state.commonSuccess = false;
      state.commonError = false;
      state.commonMessage = null;
      state.commonData = null;
    });
    builder.addCase(commonSaveData.fulfilled, (state, action) => {
      if (action.payload.status == 200) {
        state.commonLoading = false;
        state.commonSuccess = accountType == 2 ? false : true;
        state.commonError = accountType == 2 ? true : false;
        state.commonMessage = action.payload.data.message;
        state.commonData = action.payload.data.data;
      } else {
        state.commonLoading = false;
        state.commonSuccess = false;
        state.commonError = true;
        state.commonMessage =
          accountType == 2
            ? action.payload.data.message
            : action.payload.response.data.message;
        state.commonData = null;
      }
    });
    builder.addCase(commonSaveData.rejected, (state) => {
      state.commonLoading = false;
      state.commonSuccess = false;
      state.commonError = true;
      state.commonMessage = null;
      state.commonData = null;
    });
  },
});

export const { initState, initCommonState } = CommonSlice.actions;

export default CommonSlice.reducer;
